<template>
  <div class="product-container">
    <!-- Banner -->
    <div class="banner"></div>

    <!-- 主体内容 -->
    <div class="content-wrapper">
      <!-- 左侧分类 -->
      <div class="category-side">
        <h2 class="side-title">CLASSIFICATION</h2>
        <ul class="category-list">
          <li
              v-for="(item, index) in categories"
              :key="index"
              class="category-item"
              @click="selectCategory(item,index)"
              :class="{ active: currentCategory === item }"
          >
            {{ item }}
          </li>
        </ul>
      </div>

      <!-- 右侧产品展示 -->
      <div class="product-main">
        <div class="product-list">
          <div
              v-for="(product, index) in product"
              :key="index"
              class="product-item"
              @click="handleProductClick(product)"
          >
            <img
                :src="require(`@/assets/${product.image}`)"
                alt="product"
                class="product-image"
            >
            <h3 class="product-name">{{ product.name }}</h3>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :current-page="currentPage"
              :page-size="pageSize"
              @current-change="handleCurrentChange">
          </el-pagination>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductInfo",
  data() {
    return {
      currentCategory: 'GENERAL USE NITRILE GLOVES',
      categories: [
          'GENERAL USE NITRILE GLOVES',
          'GENERAL USE LATEX GLOVES',
          'GENERAL USE LATEX COATED GLOVES',
          'General Use PU Coated Gloves',
          'GENERAL USE ECONOMICAL LATEX GLOVES',
          'GERNAL USE GRIPG LOVES',
          'GENERAL USE ANTI-IMPACT GLOVES',
          'VIZ PF INSULATOR GLOVE',
          'WINTER HI-VIZ GLOVES',
          'Anti-CUT GLOVES',
          'CHEMICAL RESISTANCE SPECIALITY GLOVES',
          'GENERAL USE PVC COATED GLOVES',
          'GARDEN USE GLOVES',
      ],
      product: [],
      products: [],
        // 示例数据，需替换为真实数据
      arr1: [
      // 示例数据，需替换为真实数据
      {
        image: '1/1.jpg',
        name: 'DQ408B',
        description: '13gauge nylon liner with nitrile sandy double dipped on palm and fingers gloves Touchscreen Compatible',
        size: '7/8/9/10/11'
      },
      {
        image: '1/2.jpg',
        name: 'DQ408BS',
        description: '13gauge nylon liner with smooth nitrile 3/4 dipped and nitrile sandy coated on palm',
        size: '7/8/9/10/11'
      },
      {
        image: '1/3.jpg',
        name: 'DQ408BF',
        description: '13 gauge nylon liner with smooth nitrile fully dipped and nitrile sandy coated on palm, Waterproof',
        size: '7/8/9/10/11'
      },
      {
        image: '1/4.jpg',
        name: 'DQ408B-3/4',
        description: '15gauge nylon liner with nitrile sandy coated, 3/4 double dipped gloves',
        size: '7/8/9/10'
      },
      {
        image: '1/5.jpg',
        name: 'DQ408BD-3/4',
        description: '13 gauge nylon liner with micro foam 3/4 coated gloves, Touchscreen Compatible, Reinforced leather between thumb and index finger',
        size: '7/8/9/10'
      },
      {
        image: '1/6.jpg',
        name: 'DQB608B-3/4',
        description: '15 gauge nylon liner with 3/4 nitrile sandy coated gloves',
        size: '7/8/9/10'
      },
      {
        image: '1/7.jpg',
        name: 'DQB608B-3/4',
        description: '13gauge polyester liner with unbreathable nitrile foam coated, 3/4 dipped gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/8.jpg',
        name: 'DQB608B-3/4',
        description: '13gauge polyester liner with breathable nitrile foam coated on palm and fingers gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/9.jpg',
        name: 'DQB608B-3/4',
        description: '15gauge nylon&lycra liner with micro foam nitrile coated on palm and fingers gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/10.jpg',
        name: 'DQB608B-3/4',
        description: '15 gauge nylon liner with micro foam fully coated gloves, Touchscreen Compatible',
        size: '7/8/9/10/11'
      },
      {
        image: '1/11.jpg',
        name: 'DQB608B-3/4',
        description: '15 gauge nylon&lycra liner with washed micro foam nitrile coated on palm and fingers gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/12.jpg',
        name: 'DQB608B-3/4',
        description: '15 gauge nylon&lycra liner with washed micro foam nitrile coated and pvc dotted on palm and fingers gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/13.jpg',
        name: 'DQB608B-3/4',
        description: '18g nylon liner with micro foam nitrile coated and pvc dotted on palm gloves, Touchscreen Compatible',
        size: '7/8/9/10/11'
      },
      {
        image: '1/14.jpg',
        name: 'DQB608B-3/4',
        description: '15gaugenylon&lycra liner with micro foam nitrile coated and pvc dotted on palm and fingers gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/15.jpg',
        name: 'DQB608B-3/4',
        description: '15 gauge nylon liner with smooth nitrile fully dipped and nitrile foam coated on palm Waterproof',
        size: '7/8/9/10/11'
      },
      {
        image: '1/16.jpg',
        name: 'DQB608B-3/4',
        description: '15 gauge nylon liner with micro foam nitrile coated and pvc dotted on palm gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/17.jpg',
        name: 'DQB608B-3/4',
        description: '13gauge nylon liner with smooth nitrile coated, 3/4 dipped gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/18.jpg',
        name: 'DQB608B-3/4',
        description: '13gauge nylon liner with smooth nitrile coated on palm and finger gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/19.jpg',
        name: 'DQB608B-3/4',
        description: '18gauge nylon liner with smooth nitrile coated on palm and finger gloves',
        size: '7/8/9/10/11'
      },
      {
        image: '1/20.jpg',
        name: 'DQB608B-3/4',
        description: 'Blue nitrile full coated, high quality Smooth finished, jersey liner, safety cuff gloves',
        size: 'N/A'
      },
      {
        image: '1/21.jpg',
        name: 'DQB608B-3/4',
        description: 'Blue nitrile coated, high quality Smooth finished, jersey liner, back open safety cuff gloves',
        size: 'N/A'
      },
      {
        image: '1/22.jpg',
        name: 'DQB608B-3/4',
        description: 'Blue nitrile full coated, Smooth finished, jersey liner, knit wrist gloves',
        size: 'N/A'
      },
      {
        image: '1/23.jpg',
        name: 'DQB608B-3/4',
        description: 'Blue nitrile coated, Smooth finished, jersey liner, back open, knit wrist gloves',
        size: 'N/A'
      },
      {
        image: '1/24.jpg',
        name: 'DQB608B-3/4',
        description: 'Yellow nitrile full coated, Smooth finished Interlock liner, Knit wrist gloves',
        size: 'N/A'
      },
      {
        image: '1/25.jpg',
        name: 'DQB608B-3/4',
        description: 'Yellow nitrile coated, Smooth finished Interlock liner back open, Knit wrist gloves',
        size: 'N/A'
      },
      {
        image: '1/26.jpg',
        name: 'DQB608B-3/4',
        description: 'Nitrile coated, Chemical resistance gloves, Oil resistance great grapping',
        size: '27cm/30cm'
      },
      {
        image: '1/27.jpg',
        name: 'DQB608B-3/4',
        description: 'towel liner on palm, Safety cuff gloves',
        size: 'N/A'
      },
      {
        image: '1/28.jpg',
        name: 'DQB608B-3/4',
        description: 'towel liner on palm, knit wrist gloves',
        size: 'N/A'
      },
      {
        image: '1/29.jpg',
        name: 'DQB608B-3/4',
        description: 'Blue nitrile sandy full coated, jersey liner, knit wrist gloves',
        size: 'N/A'
      },
      {
        image: '1/30.jpg',
        name: 'DQB608B-3/4',
        description: 'Blue nitrile sandy full coated, jersey liner, safety cuff gloves',
        size: 'N/A'
      }
      // 添加更多产品数据...

    // 添加更多产品数据...
      ],
      arr2: [
        {
          image: '2/1.jpg',
          name: 'LA408B-S1',
          description: '15gauge nylon liner with Latex sandy coated on palm and fingertips gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '2/2.jpg',
          name: 'LA408B-S2',
          description: '15g nylon liner with 3/4 latex sandy coated gloves, Touchscreen Compatible',
          size: '7/8/9/10/11'
        },
        {
          image: '2/3.jpg',
          name: 'LA508B',
          description: '13gauge nylon liner with crinkle Latex coated on palm and fingertips gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '2/4.jpg',
          name: 'LA508B-3/4',
          description: '13gauge nylon liner with crinkle Latex coated, 3/4 dipped gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '2/5.jpg',
          name: 'LA508',
          description: '13gauge nylon liner with full latex coated gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '2/6.jpg',
          name: 'LA608B-S',
          description: '13gauge nylon liner with full latex coated gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '2/7.jpg',
          name: 'LA608B',
          description: '13 gauge nylon liner with latex foam coated on palm and finger gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '2/8.jpg',
          name: 'LA608B-3/4',
          description: '13gauge nylon liner with Latex foam coated, 3/4 dipped gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '2/9.jpg',
          name: 'LA708B-S',
          description: '13g nylon liner with latex sandy coated on palm and fingertips gloves, Touchscreen Compatible',
          size: '7/8/9/10/11'
        }
        // 添加更多产品数据...
      ],
      arr3: [
        {
          image: '3/1.jpg',
          name: 'LA501',
          description: 'Yellow crinkle latex full coated, Jersey liner, knit wrist gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '3/2.jpg',
          name: 'LA501B',
          description: 'Yellow crinkle latex coated, jersey liner, back open, knit wrist gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '3/3.jpg',
          name: 'LA521',
          description: 'Yellow crinkle latex full coated, Jersey liner, safety cuff gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '3/4.jpg',
          name: 'LA521B',
          description: 'Yellow crinkle latex coated, woven liner, knit wrist gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '3/5.jpg',
          name: 'LA50W',
          description: 'Yellow crinkle latex full coated, woven liner, knit wrist gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '3/6.jpg',
          name: 'LA50WB',
          description: 'Yellow crinkle latex coated, woven liner, back open, safety cuff gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '3/7.jpg',
          name: 'LA500B',
          description: 'Green crinkle latex full coated, back open, knit wrist gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '3/8.jpg',
          name: 'LA52WB',
          description: 'Yellow crinkle latex coated, woven liner, back open, safety cuff gloves',
          size: '7/8/9/10/11'
        },
        {
          image: '3/9.jpg',
          name: 'LA511',
          description: 'Yellow crinkle latex full coated, interlock liner, gauntlet gloves',
          size: '27cm/30cm/35cm'
        },
        {
          image: '3/10.jpg',
          name: 'LA510',
          description: 'Yellow crinkle latex full coated, interlock liner, gauntlet gloves',
          size: '27cm/30cm/35cm'
        }
      ],
      arr4: [
        {
          image: '4/1.jpg',
          name: 'PU608SB',
          description: '13gauge nylon liner with PU coated on palm and fingers gloves, Smooth finished',
          size: '6/7/8/9/10/11'
        },
        {
          image: '4/2.jpg',
          name: 'PU608SB-18',
          description: '18gauge nylon liner with PU Coated onpalm gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '4/3.jpg',
          name: 'PUW608SB',
          description: '15gauge nylon&lycra liner with water PU coated on palm and fingers gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '4/4.jpg',
          name: 'PU608BT',
          description: '13gauge carbon fiber liner with PU coated on palm and fingers gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '4/5.jpg',
          name: 'PU608BF',
          description: '13gauge nylon liner with PU coated on fingers gloves',
          size: '6/7/8/9/10/11'
        }
      ],
      arr5: [
        {
          image: '5/1.jpg',
          name: 'RU515-Rough palm rolled',
          description: 'Embossed diamond grip on palm and finger, rolled cuff, Individual polybag per pair',
          size: '6/7/8/9/10/11'
        },
        {
          image: '5/2.jpg',
          name: 'RU715-smooth palm',
          description: 'Embossed diamond grip on palm and finger, rolled cuff, Individual polybag per pair',
          size: '6/7/8/9/10/11'
        },
        {
          image: '5/3.jpg',
          name: 'RU715-smooth palm',
          description: 'Embossed diamond grip on palm and finger, rolled cuff, Individual polybag per pair',
          size: '6/7/8/9/10/11'
        }
      ],
      arr6: [
        {
          image: '6/1.jpg',
          name: 'PVDC7-1',
          description: '7gauge natural T/C knitted with pvc dotted on palm, elastic cuff gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '6/2.jpg',
          name: 'PVDC7-2',
          description: '7gauge natural T/C knitted with both sides pvc dotted, elastic cuff gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '6/3.jpg',
          name: 'CK1001',
          description: '7gauge natural T/C knitted cotton elastic cuff gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '6/4.jpg',
          name: 'CK1003',
          description: '10 gauge white nylon knitted gloves',
          size: '6/7/8/9/10/11'
        }
      ],
      arr7: [
        {
          image: '7/1.jpg',
          name: 'MCDQ621',
          description: '13 gauge HPPE liner with nitrile sandy coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/2.jpg',
          name: 'MCPV415',
          description: '13 gauge HPPE liner with nitrile foam coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/3.jpg',
          name: 'MC1000',
          description: '13 gauge HPPE liner with nitrile sandy coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/4.jpg',
          name: 'MC1001',
          description: '13 gauge HPPE liner with nitrile foam coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/5.jpg',
          name: 'MC1003',
          description: '13 gauge HPPE liner with nitrile sandy coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/6.jpg',
          name: 'MC1004',
          description: '13 gauge HPPE liner with nitrile foam coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/7.jpg',
          name: 'MC1002',
          description: '13 gauge HPPE liner with nitrile sandy coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/8.jpg',
          name: 'MC1008',
          description: '13 gauge HPPE liner with nitrile foam coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/9.jpg',
          name: 'MC1009',
          description: '13 gauge HPPE liner with nitrile sandy coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/10.jpg',
          name: 'MC1010',
          description: '13 gauge HPPE liner with nitrile foam coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/11.jpg',
          name: 'MC1011',
          description: '13 gauge HPPE liner with nitrile sandy coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/12.jpg',
          name: 'MC1012',
          description: '13 gauge HPPE liner with nitrile foam coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/13.jpg',
          name: 'MC1013',
          description: '13 gauge HPPE liner with nitrile sandy coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/14.jpg',
          name: 'MC1014',
          description: '13 gauge HPPE liner with nitrile foam coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/15.jpg',
          name: 'MC1015',
          description: '13 gauge HPPE liner with nitrile sandy coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        },
        {
          image: '7/16.jpg',
          name: 'MC1016',
          description: '13 gauge HPPE liner with nitrile foam coated on palm and fingers, back with TPR impact gloves',
          size: '6/7/8/9/10/11'
        }
      ],
      arr8: [
        { image: '8/1.jpg', name: 'LA5091', description: 'Heavy thermal lined,7gauge acrylic terry liner with crinkle latex coated on Palm&Thumb gloves' },
        { image: '8/2.jpg', name: 'LA5092', description: 'Heavy thermal lined,6.7gauge acrylic terry liner with crinkle latex coated on Palm&Thumb gloves' },
        { image: '8/3.jpg', name: 'LA5093', description: 'Heavy thermal lined,7gauge acrylic terry liner with crinkle latex coated,3/4 dipped gloves' },
        { image: '8/4.jpg', name: 'LA5003', description: 'Heavy thermal lined,7gauge acrylic terry liner with crinkle latex coated,3/4 dipped gloves' },
        { image: '8/5.jpg', name: 'LA5095', description: 'Heavy thermal lined,7gauge acrylic terry liner with crinkle latex full coated gloves' },
        { image: '8/6.jpg', name: 'LA5096', description: '13 gauge nylon+7gauge acrylic terry liner with latex foam coated on palm and finger gloves' },
        { image: '8/7.jpg', name: 'LA5097', description: '13 gauge polyester liner+7 gauge acrylic terry liner with latex foam coated on palm and finger gloves' },
        { image: '8/8.jpg', name: 'LA5098', description: 'Heavy thermal lined,7 gauge nylon liner+7 gauge acrylic terry liner with latexfoam coated on palm and finger gloves' },
        { image: '8/9.jpg', name: 'LA5099', description: 'Heavy thermal lined,10 gauge acrylic terry liner with latex sandy coating on gloves,Touchscreen Compatible 2131X' },
        { image: '8/10.jpg', name: 'LA5100', description: '13 gauge nylon liner+7 gauge acrylic terry liner with 3/4 latex foam coated gloves,Touchscreen Compatible 2131X' },
        { image: '8/11.jpg', name: 'LA5101', description: '15 gauge nylon liner with smooth latex fully coating+ latex sandy coating on palm and finger gloves,Touchscreen Compatible and Waterproof 4121X' },
        { image: '8/12.jpg', name: 'LA5102', description: '13 gauge nylon liner with smooth latex fully coating+ latex sandy coating on palm and finger gloves, Touchscreen Compatible, Waterproof;Reinforced leather between thumb and index finger.4121X' },
        { image: '8/13.jpg', name: 'LA5103', description: '13 gauge fluo yellow polyester/Tc gauge acrylic terry liner with latex sandy coated on palm and finger gloves,Touchscreen Compatible 2131X' },
        { image: '8/14.jpg', name: 'LA5104', description: '13 gauge nylon liner + acrylic terry liner with 3/4 latex sandy coated gloves. Reinforced leather between thumb and index finger,Touchcreen' },
        { image: '8/15.jpg', name: 'NR5091', description: '13 gauge nylon+7 gauge terry liner with gauge acrylic Nitrile Foam 3/4 coated gloves' },
        { image: '8/16.jpg', name: 'NR5092', description: '13 gauge nylon+7 gauge acrylic terry liner with liner Nitrile double dipped,Nitrile smooth fully dipped/Nitrile sandy finish 3/4 dipped gloves' },
        { image: '8/17.jpg', name: 'NR5093', description: '13 gauge nylon+7 gauge acrylic terry liner with nitrile foam coated on palm and finger gloves,Touchscreen Compatible 4121X' },
        { image: '8/18.jpg', name: 'NR5094', description: '13 gauge nylon+7 gauge acrylic terry liner with 3/4 nitrile sandy coated on palm and finger gloves,Touchscreen Compatible 4121X' },
        { image: '8/19.jpg', name: 'NR5095', description: '13 gauge nylon+7 gauge acrylic terry liner with nitrile sandy coated on palm and finger gloves,Touchscreen Compatible 4121X' },
        { image: '8/20.jpg', name: 'NR5096', description: '13 gauge nylon+7 gauge acrylic terry liner with nitrile sandy coated on palm and finger gloves,Touchscreen Compatible 4121X' }
      ],
      arr9:[
        { image: '9/1.jpg', name: 'PV8045', description: 'Smooth finish', size: '' },
        { image: '9/2.jpg', name: 'PV8145', description: 'Smooth finish', size: '27/30/35cm' },
        { image: '9/3.jpg', name: 'PV8245', description: '27/30/35cm', size: '' },
        { image: '9/4.jpg', name: 'PVW4110', description: '15 gauge seamless acrylic towel liner full double dip,PVC sandy finished,gauntlet gloves', size: '27/30/33cm' },
        { image: '9/5.jpg', name: 'PE015', description: 'Highest quality cowhide leather, full palm,fleece jersey liner,Rubberized safety  cuff gloves', size: '27/30/33cm' },
      ],
      arr10: [
        {"image": "10/1.jpg", "name": "DM6081", "description": "13 gauge HPPE+glass fiber mixed liner with crinkle latex coated on palm cutting gloves, Reinforced leather between thumb and index finger", "size": "C/A3"},
        {"image": "10/2.jpg", "name": "DM6082", "description": "13 gauge HPPE+glass fiber mixed liner with Nitrile smooth finished, coated on palm, cutting gloves", "size": "3/5"},
        {"image": "10/3.jpg", "name": "DM6083", "description": "13 gauge HPPE+glass fiber mixed liner with Latex sandy finish coated on palm cutting gloves", "size": "3/5"},
        {"image": "10/4.jpg", "name": "DM6084", "description": "13 gauge HPPE+glass fiber mixed liner with PU coated on palm cutting gloves, Reinforced leather between thumb and index finger", "size": "C/A3"},
        {"image": "10/5.jpg", "name": "DM6085", "description": "13 gauge HPPE+glass fiber mixed liner with Latex sandy finish 3/4 dipped cutting gloves", "size": "3/5"},
        {"image": "10/6.jpg", "name": "DM6086", "description": "13 gauge HPPE+glass fiber mixed liner with Latex foam coated on palm cutting gloves", "size": "3/5"},
        {"image": "10/7.jpg", "name": "DM6083-18", "description": "18 gauge HPPE+glass fiber mixed liner with PU coated on palm cutting gloves, Dipping: palm", "size": "3/5"},
        {"image": "10/8.jpg", "name": "SM6083", "description": "13 gauge Synthetic mix yarn+glass fiber mixed liner with PU palm coated gloves", "size": "3/5"},
        {"image": "10/9.jpg", "name": "DM6087", "description": "13 gauge HPPE+glass fiber mixed liner with nitrile sandy ful coated cutting gloves", "size": "3/5"},
        {"image": "10/10.jpg", "name": "DM6088", "description": "13 gauge HPPE+glass fiber mixed liner with nitrile foam coated on palm cutting gloves", "size": "3/5"},
        {"image": "10/11.jpg", "name": "DM6092", "description": "13 gauge HPPE(high performance polyethylene)/Glass fiber/Nylon liner with sandy Nitrile coated glove", "size": "3/5"},
        {"image": "10/12.jpg", "name": "DM1801", "description": "18 gauge HPPE,glass,steel fiber mixed liner with nitrile foam coated cutting gloves, Touchscreen Compatible", "size": "C/A3"},
        {"image": "10/13.jpg", "name": "DM1802", "description": "18 gauge polyester,nylon,glass fiber mixed liner with nitrile foam coated cutting gloves, Touchscreen Compatible, Reinforced leather between thumb and index finger", "size": "B/A2"},
        {"image": "10/14.jpg", "name": "DM1803", "description": "18 gauge HPPE,steel fiber mixed liner with nitrile foam coated+pvc dotted cutting gloves, Reinforced leather between thumb and index finger", "size": "C/A3"},
        {"image": "10/15.jpg", "name": "DM1804", "description": "18 gauge HPPE,steel fiber mixed liner with washed nitrile foam coated cutting gloves, Touchscreen Compatible, Reinforced leather between thumb and index finger", "size": "C/A3"},
        {"image": "10/16.jpg", "name": "DM1805", "description": "18 gauge HPPE,nylon,lycra liner with 3/4 nitrile smooth coated and nitrile sandy coating on palm and finger, Touchscreen Compatible", "size": "B/A2"},
        {"image": "10/17.jpg", "name": "DM1806", "description": "18 gauge HPPE,glass fiber mixed liner with latex sandy coated cutting gloves, Touchscreen Compatible", "size": "B/A2"},
        {"image": "10/18.jpg", "name": "DM1807", "description": "18 gauge seamless knit blended liner with nitrile sandy coated on palm cutting gloves", "size": "E/A5"},
        {"image": "10/19.jpg", "name": "DM1808", "description": "18 gauge HPPE,steel fiber mixed liner with PU coated cutting gloves, Touchscreen Compatible", "size": "C/A3"},
        {"image": "10/20.jpg", "name": "DM1809", "description": "18 gauge HPPE,steel fiber mixed liner with nitrile sandy coated cutting gloves, Touchscreen Compatible", "size": "C/A3"},
        {"image": "10/20.jpg", "name": "DM1501", "description": "15 gauge HPPE,steel fiber mixed liner with nitrile foam coated on palm cutting gloves, Reinforced leather between thumb and index finger, Touchscreen Compatible", "size": "D/A4"},
        {"image": "10/21.jpg", "name": "DM1502", "description": "15 gauge HPPE,glass fiber mixed liner with nitrile sandy coated cutting gloves", "size": "C/A3"},
        {"image": "10/22.jpg", "name": "DM1503", "description": "15 gauge HPPE,glass fiber mixed liner with latex sandy coated cutting gloves, Touchscreen Compatible", "size": "C/A3"},
        {"image": "10/23.jpg", "name": "DM1504", "description": "15 gauge HPPE,steel fiber mixed liner with washed nitrile foam coated cutting gloves, Touchscreen Compatible, Reinforced leather between thumb and index finger", "size": "D/A4"},
        {"image": "10/24.jpg", "name": "DM1505", "description": "15 gauge HPPE,glass fiber mixed liner with PU coated cutting gloves, Touchscreen Compatible, Reinforced leather between thumb and index finger", "size": "B/A2"},
        {"image": "10/25.jpg", "name": "DM1506", "description": "15 gauge HPPE+glass fiber mixed liner with PU coated cutting gloves, Touchscreen Compatible", "size": "D/A4"},
        {"image": "10/26.jpg", "name": "DM1507", "description": "15 gauge HPPE+glass fiber mixed liner with latex sandy coated cutting gloves, Touchscreen Compatible", "size": "C/A3"},
        {"image": "10/27.jpg", "name": "DM6093", "description": "13 gauge HPPE,glass and steel fiber mixed liner with latex sandy finish 3/4 coated cutting gloves", "size": "E/A5"},
        {"image": "10/28.jpg", "name": "DM6094", "description": "13 gauge HPPE,glass and steel fiber mixed liner with nitrile sandy coated on padded palm cutting gloves", "size": "E/A5"},
        {"image": "10/29.jpg", "name": "DM6095", "description": "13 gauge HPPE,steel fiber mixed liner with latex sandy coated on palm cutting gloves", "size": "D/A4"},
        {"image": "10/30.jpg", "name": "DM6096", "description": "13 gauge HPPE glass and steel fiber mixed liner with washed nitrile foam coated cutting gloves, Touchscreen Compatible", "size": "E/A5"},
        {"image": "10/30.jpg", "name": "DM6097", "description": "13 gauge HPPE,glass and steel fiber mixed liner with washed sandy coated cutting gloves, Reinforced leather between thumb and index finger", "size": "F/A6"},
        {"image": "10/31.jpg", "name": "DM6098", "description": "13 gauge HPPE,glass fiber mixed liner with double nitrile coated cutting gloves, Waterproof", "size": "C/A3"},
        {"image": "10/32.jpg", "name": "DM6099", "description": "13 gauge HPPE,glass and steel fiber mixed liner with PU coated cutting gloves, Touchscreen Compatible", "size": "D/A4"},
        {"image": "10/33.jpg", "name": "DM6100", "description": "13 gauge HPPE,steel and glass fiber mixed cutting gloves, food touch", "size": "A7 for finger, A3 for palm"},
        {"image": "10/34.jpg", "name": "DM6101", "description": "10 gauge HPPE mixed terry cutting gloves", "size": "D/A4"},
        {"image": "10/35.jpg", "name": "DM6102", "description": "10 gauge HPPE mixed terry cutting gloves", "size": "C/A3"},
        {"image": "10/36.jpg", "name": "KV508B", "description": "10 gauge Aramid fiber knitted liner with latex crinkle coated on palm cutting gloves", "size": "C/A3"},
        {"image": "10/37.jpg", "name": "DM6103", "description": "13 gauge Diamond Yarn,Glass Fiber mixed liner with PVC dotted cutting gloves, Reinforced leather between thumb and index finger, Touchscreen Compatible", "size": "F/A6"},
        {"image": "10/38.jpg", "name": "DM6104", "description": "13 gauge Diamond Yarn,Glass Fiber mixed liner with nitrile foam cutting gloves, Touchscreen Compatible", "size": "F/A6"},
        {"image": "10/39.jpg", "name": "DM6105", "description": "13 gauge HPPE,steel and glass fiber mixed liner with nitrile coated cutting gloves, Long sleeve", "size": "D/A4"},
        {"image": "10/39.jpg", "name": "SLD002", "description": "13 gauge HPPE,steel and glass fiber mixed knitted cutting sleeve", "size": "D/A4"},
        {"image": "10/40.jpg", "name": "KV1007", "description": "7 gauge Aramid fiber knitted cutting gloves", "size": "D/A5"},
        {"image": "10/41.jpg", "name": "KV1008", "description": "13 gauge Aramid+steel fabric with PU palm coated cutting gloves", "size": "D/A3"},
        {"image": "10/42.jpg", "name": "KV1009", "description": "13 gauge Aramid fiber+nylon+glass fiber mixed liner with PU palm coated cutting glove", "size": "D/A3"},
        {"image": "10/43.jpg", "name": "KV1010", "description": "18 gauge Aramid fiber knitted liner with PU Palm coated cutting gloves", "size": "D/A3"},
        {"image": "10/44.jpg", "name": "KV6083-S", "description": "13 gauge Aramid fiber knitted liner with smooth nitrile coated on palm cutting gloves", "size": "D/A3"},
        {"image": "10/45.jpg", "name": "KV6083-18", "description": "7 gauge acrylic terry liner+HPPE fiber mixed liner with crinkle latex coated, 3/4 dipped cutting gloves", "size": "D/A3"},
        {"image": "10/46.jpg", "name": "KV6083", "description": "13 gauge HPPE+glass fiber mixed liner & acrylic terry liner with sandy nitrile coated, 3/4 dipped cutting gloves", "size": "3/5"},
        {"image": "10/47.jpg", "name": "KV608B", "description": "13 gauge Aramid +glass fiber mixed knitted cutting sleeve", "size": "D/A3"},
        {"image": "10/48.jpg", "name": "DM6091", "description": "13 gauge HPPE+glass fiber mixed knitted cutting sleeve", "size": "D/A3"},
        {"image": "10/49.jpg", "name": "DM6090", "description": "13 gauge Synthetic mix yarn+glass fiber mixed knitted cutting sleeve", "size": "D/A3"},
        {"image": "10/50.jpg", "name": "SLK001", "description": "13 gauge HPPE,steel and glass fiber mixed knitted cutting sleeve", "size": "D/A4"},
        {"image": "10/51.jpg", "name": "SLS002", "description": "13 gauge HPPE,steel and glass fiber mixed knitted cutting sleeve", "size": "D/A4"},
        {"image": "10/52.jpg", "name": "SLD001", "description": "13 gauge HPPE,steel and glass fiber mixed knitted cutting sleeve", "size": "D/A4"},
      ],
      arr11:[
        {image: '11/1.jpg', name: 'PV401', description: '100% interlock/jersey lined double dip PVC sandy finish',size:''},
        {image: '11/2.jpg', name: 'PV411', description: '100% interlock/jersey lined double dip PVC sandy finish',size:''},
        {image: '11/3.jpg', name: 'PV415', description: '100%cotton  seamless    lined,Triple   dipped pvc sandy finish',size:''},
        {image: '11/4.jpg', name: 'PVJX610', description: 'Extended\n' +
              'bonded    sleeve,elasticated border,\n' +
              'Liner:Interlock/Jersey\n' +
              'Cuff:35cm  pvc  gloves+25cm raincoat   sleeve\n' +
              'Finish:pvc  Smooth  finsih',size:''},
        {image: '11/5.jpg', name: 'PVJX210', description: 'fish、oil、cold resistant\n' +
              'Liner:interlock   lined/ Jersey  Lined',size:''},
        {image: '11/6.jpg', name: '21131G-MIL', description: 'Embossed\n' +
              'grip on palm ang fingers 13"length\n' +
              'Dipping:Green   Nitrile',size:''},
      ],
      arr12:[
        {image: '12/1.jpg', name: 'PV8045', description: 'Interlock    liner\n' +
              '\n' +
              'ITEM       NO:PV601\n' +
              'Description:Jersey    liner Knit  wrist'},
        {image: '12/2.jpg', name: 'PV8045', description: 'Interlock    liner\n' +
              '\n' +
              'ITEM       NO:PV601\n' +
              'Description:Jersey    liner Knit  wrist'},
        {image: '12/3.jpg', name: 'PV8045', description: 'Black  pvc  full coated,rough   finished,\n' +
              'interlock    liner,gauntlet gloves',size:'27/30/35/40/45cm'
              },
        {image: '12/4.jpg', name: 'PV8045', description: 'Pvc full coated, interlock on back,towel\n' +
              'liner on palm,knit wrist gloves',size:'27/30/35/40/45cm'},
        {image: '12/5.jpg', name: 'PV8045', description: 'Pvc full coated, interlock on back,towel\n' +
              'liner on palm,knit wrist gloves',size:'27/30/35/40/45cm'},
        {image: '12/6.jpg', name: 'PV8045', description: 'Black PVC chips coated on palm,   interlock liner,knit wrist gloves'},
        {image: '12/7.jpg', name: 'PV8045', description: 'Black PVC\n' +
              'chips full coated,interlock liner,knit wrist gloves'},
      ],
      arr13:[
        {image: '13/1.jpg', name: 'PV8045', description: 'Black PVC\n' +
              'chips full coated,interlock liner,knit wrist gloves'},
        {image: '13/2.jpg', name: 'PV8045', description: 'Colored crinkle latex coated   ,white interlock liner,back open,knit wrist\n' +
              'Finish:Crinkle  latex'},
        {image: '13/3.jpg', name: 'PV8045', description: 'Colored crinkle latex coated   ,white interlock liner,back open,knit wrist\n' +
              'Finish:Crinkle  latex'},
        {image: '13/4.jpg', name: 'PV8045', description: 'Colored crinkle latex coated   ,white interlock liner,back open,knit wrist\n' +
              'Finish:Crinkle  latex'},
        {image: '13/5.jpg', name: 'PV8045', description: 'Colored crinkle latex coated   ,white interlock liner,back open,knit wrist\n' +
              'Finish:Crinkle  latex'},
        {image: '13/6.jpg', name: 'PV8045', description: '13gauge colored nylon liner with colored latex foam coated on palm   and finger gloves'},
        {image: '13/7.jpg', name: 'PV8045', description: '13gauge colored nylon liner  with colored crinkle latex coated on palm and finger gloves\n' +
              'Finish:Crinkle\n'},
        {image: '13/8.jpg', name: 'PV8045', description: '10 Gauge T/C seamless\n' +
              'knitted liner with crinkle latex coated on palm and fingertips gloves'},
        {image: '13/9.jpg', name: 'PV8045', description: '10 Gauge T/C liner    knitted liner with 3/4 crinkle latex coated gloves 2242X'},
        {image: '13/10.jpg', name: 'PV8045', description: '13gauge colored nylon liner with\n' +
              'colored nitrile coated on palm and finger gloves,\n' +
              'Finish:Smooth finished '},
        {image: '13/11.jpg', name: 'PV8045', description: '13gauge colored nylon liner with\n' +
              'colored nitrile coated on palm and finger gloves,\n' +
              'Finish:Smooth finished '},
        {image: '13/12.jpg', name: 'PV8045', description: '13 gauge nylon liner with 3/4 nitrile foam coated gloves,Touchscreen'},
        {image: '13/13.jpg', name: 'PV8045', description: '13 gauge nylon liner with 3/4 nitrile foam coated gloves,Touchscreen'},
        {image: '13/14.jpg', name: 'PV8045', description: '13gauge colored nylon liner with colored nitrile coated gloves,\n'},
        {image: '13/15.jpg', name: 'PV8045', description: '13gauge floral nylon liner  with colored nitrile coated on palm and fingers gloves\n'},
        {image: '13/16.jpg', name: 'PV8045', description: '13gauge floral nylon liner  with colored nitrile coated on palm and fingers gloves\n'},
        {image: '13/17.jpg', name: 'PV8045', description: '13gauge floral nylon liner  with colored nitrile coated on palm and fingers gloves\n'},
        {image: '13/18.jpg', name: 'PV8045', description: '13gauge floral nylon liner  with colored nitrile coated on palm and fingers gloves\n'},
        {image: '13/19.jpg', name: 'PV8045', description: '13gauge floral nylon liner  with colored nitrile coated on palm and fingers gloves\n'},
      ],
      total:0,
      currentPage: 1, // 当前页码
      pageSize: 12,   // 每页显示的产品数量
        backgroundColo: "#ea7511"
    }
  },
  computed: {

  },
  mounted() {
    this.products = this.arr1
    this.total = this.arr1.length
    this.product = this.paginatedProducts()
  },

  methods: {
    selectCategory(category,index) {
      console.log('当前选中的分类：', category)
      if (index === 0) {this.products = this.arr1;this.total = this.arr1.length}
      if (index === 1) {this.products = this.arr2;this.total = this.arr2.length}
      if (index === 2) {this.products = this.arr3;this.total = this.arr3.length}
      if (index === 3) {this.products = this.arr4;this.total = this.arr4.length}
      if (index === 4) {this.products = this.arr5;this.total = this.arr5.length}
      if (index === 5) {this.products = this.arr6;this.total = this.arr6.length}
      if (index === 6) {this.products = this.arr7;this.total = this.arr7.length}
      if (index === 7) {this.products = this.arr8;this.total = this.arr8.length}
      if (index === 8) {this.products = this.arr9;this.total = this.arr9.length}
      if (index === 9) {this.products = this.arr10;this.total = this.arr10.length}
      if (index === 10) {this.products = this.arr11;this.total = this.arr11.length}
      if (index === 11) {this.products = this.arr12;this.total = this.arr12.length}
      if (index === 12) {this.products = this.arr13;this.total = this.arr13.length}
      this.currentCategory = category
      this.currentPage = 1 // 切换分类时重置页码
      this.product = this.paginatedProducts()
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.product = this.paginatedProducts()
    },
    paginatedProducts() {
      console.log('this.products',this.products)
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.products.slice(start, end);
    },
    handleProductClick(product) {
      this.$router.push({
        name: 'ProductDetail',
        query: product
      })
    }
  }
}
</script>

<style scoped>
/* Banner样式 */
.banner {
  width: 100%;
  height: 300px;
  background: url('@/assets/banner1.jpg') no-repeat center/cover;
  margin-bottom: 30px;
}

/* 主体布局 */
.content-wrapper {

  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 1100px;
  padding: 0 20px;
  padding-bottom: 80px;
}

/* 左侧分类 */
.category-side {
  width: 300px;
    font-size: 14px;
  padding-right: 30px;
}
.category-list {
  list-style: none;
  padding: 0;
}
.category-item {
  padding: 12px 20px;
  margin: 8px 0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.category-item:hover,
.category-item.active {
  background: #ea7511;
  color: white;
}

/* 右侧产品展示 */
.product-main {
  flex: 1;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.product-item {
  width: calc(25% - 20px); /* 5列布局 */
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.1);
  transition: transform 0.3s;
}
.product-item:hover {
  transform: translateY(-5px);
}
.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}
.product-name {
  margin: 10px 0 5px;
  text-align: center;
  font-size: 16px;
  color: #333;
}
.product-desc {
  font-size: 12px;
  color: #666;
  line-height: 1.4;
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* 响应式适配 */
@media (max-width: 768px) {
  .category-side {
    width: 100%;
    margin-bottom: 20px;
  }
  .product-item {
    width: calc(50% - 10px); /* 移动端2列布局 */
  }
}


.pagination {
  margin-top: 30px;
  display: flex;
  flex-direction: row-reverse;
}
.product-container{
  height: 100%;
  background-color: #b9bdaf;
}
.page-info {
  color: #606266;
}
.product-main {
  width: 100%;
}
/* 修改产品列表循环为使用paginatedProducts */
.product-list {
width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
</style>
