<template>
  <div >
    <!-- Banner -->
    <div class="banner">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in bannerImages" :key="index">
          <img :src="item" alt="Banner" style="width: 100%; height: 100%;">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="home-container">
      <!-- 公司介绍 -->
      <div class="section company-intro">
          <div class="left">
            <div class="text">
              <h2>Customer Sample Collection</h2>
              <p>1. Used for quality reference. By retaining samples, subsequent sales of products can be compared with them to ensure the stability and consistency of product quality。</p>
              <p>2. As a communication tool. If there are differences in product details with customers, or if there is a need to explore new markets or develop new customers, samples can serve as the most intuitive communication tool, clearly demonstrating the company's production capacity and product standards.</p>
            </div>
          </div>
          <div>
            <div class="image">
              <img src="@/assets/home_C_1.jpg" alt="Company Image 2" style="width: 100%;">
            </div>
          </div>
      </div>

      <!-- 分割图 -->
      <div class=" separator">
        <img src="@/assets/fenge1.webp" alt="Separator" style="width: 100%;">
      </div>

      <!-- 产品介绍 -->
      <div class=" section product-intro">
        <div class="PRODUCTS_box">
          <div class="list">
            <div class="img_box">
              <img src="../assets/home_P_1.jpg" alt="">
            </div>
            <div class="PRODUCTS_title">
              Anti-CUT GLOVES
            </div>
            <div class="PRODUCTS_text">
              <p>Anti impact</p>
            </div>
            <div class="PRODUCTS_butt">
              <el-button type="primary" round @click="handleProductClick('PRODUCTS_box')">view more</el-button>
            </div>
          </div>
          <div class="list">
            <div class="img_box">
              <img src="../assets/home_P_2.jpg" alt="">
            </div>
            <div class="PRODUCTS_title">
              GENERAL USE LATEX GLOVES
            </div>
            <div class="PRODUCTS_text">
              <p>Puncture resistance</p>
            </div>
            <div class="PRODUCTS_butt">
              <el-button type="primary" round @click="handleProductClick('PRODUCTS_box')">view more</el-button>
            </div>
          </div>
          <div class="list">
            <div class="img_box">
              <img src="../assets/home_P_3.jpg" alt="">
            </div>
            <div class="PRODUCTS_title">
              WINTER HI-VIZ GLOVES
            </div>
            <div class="PRODUCTS_text">
              <p>Anti static electricity</p>
            </div>
            <div class="PRODUCTS_butt">
              <el-button type="primary" round @click="handleProductClick('PRODUCTS_box')">view more</el-button>
            </div>
          </div>
          <div class="list">
            <div class="img_box">
              <img src="../assets/home_P_4.jpg" alt="">
            </div>
            <div class="PRODUCTS_title">
              GARDEN USE GLOVES
            </div>
            <div class="PRODUCTS_text">
              <p>Flexible and durable</p>
            </div>
            <div class="PRODUCTS_butt">
              <el-button type="primary" round @click="handleProductClick('PRODUCTS_box')">view more</el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- 分割图 -->
      <div class=" separator">
        <img src="@/assets/fenge2.webp" alt="Separator" style="width: 100%;">
      </div>

      <!-- 团队介绍 -->
      <div class="section company-intro tuskkd">
        <div>
          <div class="image">
            <img src="@/assets/666.jpg" alt="Company Image 2" style="width: 100%;">
          </div>
        </div>
        <div class="left">
          <div class="text">
            <h2>Service Advantages</h2>
            <p>1. **Quality Assurance Strictly control product quality, and both raw materials and production processes have undergone multiple inspections to ensure that every pair of gloves is safe and reliable. </p>
            <p>2. **Custom Service**: Provide customized services such as different specifications, colors, thicknesses, and packaging according to customer needs to meet diverse demands.</p>
            <p>3. **Global Supply**: Cooper with several internationally renowned rubber glove manufacturers to ensure a stable supply and support large-volume order delivery. </p>
            <p>4. **Efficient Logistics**: Relying on a perfect logistics, we provide customers with fast and convenient distribution services to reduce transportation costs. </p>
            <p>5. **Professional Team**: We have an experienced sales and technical support team to provide customers one-stop services from product selection to after-sales service. </p>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      bannerImages: [
        require('@/assets/banner2.jpg'),
        require('@/assets/banner3.jpg'),
      ],
    };
  },
  methods:{
    handleProductClick () {
      this.$router.push({
        name: 'ProductInfo'
      })
    }
  }
};
</script>

<style scoped>
.home-container {


}

.banner {
  width: 100%;
  height: 800px; /* 根据需要调整高度 */
}

.section {

  width: 1400px;
  margin: 50px auto;
}
.company-intro{
    display: flex;
    justify-content: space-between;
}
.company-intro .image{
  width: 600px;
}
.company-intro .left{
  width: 40%;
  padding-left: 30px;
  text-align: center;
}
.company-intro h2{
  color: #333;
  font-family: 微软雅黑;
  padding-bottom: 50px;
}
.company-intro p{
  line-height: 50px;
  margin-bottom: 30px;
}
.company-intro .text, .team-intro .text {
  margin-bottom: 20px;
}

.company-intro .image, .team-intro .image {
  margin-top: 20px;
}
.product-intro{
  margin: 150px auto;
}
.product-intro .image {
  margin-bottom: 20px;
}

.separator {
  width: 100%;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
>>> .el-carousel__container {
    position: relative;
    height: 800px;
}
.PRODUCTS_box{
  display: flex;
}
.list{
  width: 25%;
  margin: 10px 10px;
  background-color: #FFFFFF;
}
.PRODUCTS_text p{
  text-align: center;
  font-size: 12px;
  line-height: 32px;
}
.PRODUCTS_butt{
  margin : 20px 0;
  text-align: center;
}
.img_box img{
  width: 100%;
  height: 250px;
}
.PRODUCTS_title{
  text-align: center;
  font-size: 21px;
  line-height: 32px;
}
.tuskkd p{
  line-height: 25px;
  margin-bottom: 30px;
}
</style>
