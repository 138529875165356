var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"banner"},[_c('el-carousel',{attrs:{"interval":5000,"arrow":"always"}},_vm._l((_vm.bannerImages),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item,"alt":"Banner"}})])}),1)],1),_c('div',{staticClass:"home-container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"section product-intro"},[_c('div',{staticClass:"PRODUCTS_box"},[_c('div',{staticClass:"list"},[_vm._m(2),_c('div',{staticClass:"PRODUCTS_title"},[_vm._v(" Anti-CUT GLOVES ")]),_vm._m(3),_c('div',{staticClass:"PRODUCTS_butt"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.handleProductClick('PRODUCTS_box')}}},[_vm._v("view more")])],1)]),_c('div',{staticClass:"list"},[_vm._m(4),_c('div',{staticClass:"PRODUCTS_title"},[_vm._v(" GENERAL USE LATEX GLOVES ")]),_vm._m(5),_c('div',{staticClass:"PRODUCTS_butt"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.handleProductClick('PRODUCTS_box')}}},[_vm._v("view more")])],1)]),_c('div',{staticClass:"list"},[_vm._m(6),_c('div',{staticClass:"PRODUCTS_title"},[_vm._v(" WINTER HI-VIZ GLOVES ")]),_vm._m(7),_c('div',{staticClass:"PRODUCTS_butt"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.handleProductClick('PRODUCTS_box')}}},[_vm._v("view more")])],1)]),_c('div',{staticClass:"list"},[_vm._m(8),_c('div',{staticClass:"PRODUCTS_title"},[_vm._v(" GARDEN USE GLOVES ")]),_vm._m(9),_c('div',{staticClass:"PRODUCTS_butt"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.handleProductClick('PRODUCTS_box')}}},[_vm._v("view more")])],1)])])]),_vm._m(10),_vm._m(11)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section company-intro"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"text"},[_c('h2',[_vm._v("Customer Sample Collection")]),_c('p',[_vm._v("1. Used for quality reference. By retaining samples, subsequent sales of products can be compared with them to ensure the stability and consistency of product quality。")]),_c('p',[_vm._v("2. As a communication tool. If there are differences in product details with customers, or if there is a need to explore new markets or develop new customers, samples can serve as the most intuitive communication tool, clearly demonstrating the company's production capacity and product standards.")])])]),_c('div',[_c('div',{staticClass:"image"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/home_C_1.jpg"),"alt":"Company Image 2"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separator"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/fenge1.webp"),"alt":"Separator"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("../assets/home_P_1.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PRODUCTS_text"},[_c('p',[_vm._v("Anti impact")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("../assets/home_P_2.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PRODUCTS_text"},[_c('p',[_vm._v("Puncture resistance")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("../assets/home_P_3.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PRODUCTS_text"},[_c('p',[_vm._v("Anti static electricity")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("../assets/home_P_4.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PRODUCTS_text"},[_c('p',[_vm._v("Flexible and durable")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separator"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/fenge2.webp"),"alt":"Separator"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section company-intro tuskkd"},[_c('div',[_c('div',{staticClass:"image"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/666.jpg"),"alt":"Company Image 2"}})])]),_c('div',{staticClass:"left"},[_c('div',{staticClass:"text"},[_c('h2',[_vm._v("Service Advantages")]),_c('p',[_vm._v("1. **Quality Assurance Strictly control product quality, and both raw materials and production processes have undergone multiple inspections to ensure that every pair of gloves is safe and reliable. ")]),_c('p',[_vm._v("2. **Custom Service**: Provide customized services such as different specifications, colors, thicknesses, and packaging according to customer needs to meet diverse demands.")]),_c('p',[_vm._v("3. **Global Supply**: Cooper with several internationally renowned rubber glove manufacturers to ensure a stable supply and support large-volume order delivery. ")]),_c('p',[_vm._v("4. **Efficient Logistics**: Relying on a perfect logistics, we provide customers with fast and convenient distribution services to reduce transportation costs. ")]),_c('p',[_vm._v("5. **Professional Team**: We have an experienced sales and technical support team to provide customers one-stop services from product selection to after-sales service. ")])])])])
}]

export { render, staticRenderFns }