import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/Home.vue'
import Home from "@/views/Home.vue";
import ProductInfo from "@/views/ProductInfo.vue";
import CompanyInfo from "@/views/CompanyInfo.vue";
import ContactUs from "@/views/ContactUs.vue";
import ProductDetail from '../views/ProductDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/product-info',
    name: 'ProductInfo',
    component: ProductInfo,
  },
  {
    path: '/company-info',
    name: 'CompanyInfo',
    component: CompanyInfo,
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/detail',
    name: 'ProductDetail',
    component: ProductDetail,
    props: (route) => ({ product: route.query }) // 通过路由参数传递产品数据
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
