<!-- CompanyInfo.vue -->
<template>
  <div class="CompanyInfo">
    <div class="top">
      <img alt="Company Image 1" src="@/assets/banner2.jpg" style="width: 100%;">
    </div>
    <div class="main">
      <!--复制的代码-->
      <div class="jz_web_row__content">
        <div _index="0" _row="202" class="jz_web_col jz_web_col0 col_mobi_order_-1 J_disable_add_material">
          <div class="web_col_content">
            <div id="module397" class="form jz_module jz_module_style_6 responsive_fontSize jz_project jz_project_397" module="" module-index="0" projectid="397"
                 rowid="202">
              <div class="module_wrap J_project_sort_handle"><!---->
                <div class="module_content">
                  <div id="397" class="module_text_content" module-index="0" rowid="202">
                    <div class="fkeditor-wrap">
                      <div class="fk-editor">
                        <div align="center" style="line-height: 30px;"><span
                            style="color: rgb(18, 97, 172); font-size: 20px; text-align: left;">About Us</span></div>
                        <div align="center" style="line-height: 30px;"><span
                            style="color: rgb(18, 97, 172); font-size: 14px; text-align: left;">▼</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> <!----> <!----> <!----></div>
            <div id="module333" class="form jz_module jz_module_style_6 responsive_fontSize jz_project jz_project_333" data-animations-id="333" module="" module-index="1"
                 projectid="333"
                 rowid="202" style="animation: auto ease 0s 1 normal none running none;">
              <div class="module_wrap J_project_sort_handle"><!---->
                <div class="module_content">
                  <div id="333" class="module_text_content" module-index="1" rowid="202">
                    <div class="fkeditor-wrap">
                      <div class="fk-editor">
                        <div style="line-height: 36px;">
                          <div align="center" style="font-size: 13px;"><b><span
                              style="color: rgb(89, 87, 87); font-family: 微软雅黑; font-size: 14px;"><span
                              style="color: rgb(255, 171, 145);"><span style="color: rgb(16, 87, 153);">——</span></span></span></b>
                          </div>
                          <div align="center" style=""><font color="#595757"><span style="font-size: 22px;">
                            Brilliant Union Private Limited is a Singapore registered company incorporated in 2014. Brilliant Union designs and supplies unique,high-quality protective products from Safety Helmets,Personal Protective Equipment(PPE),Uniforms to Footwear.We have various products such as Latex/Nitrile/PVC glove,PVC/PU protective suit and Apron/raincoat.These products are widely used in many different industries such as construction,electricalelectronics,automobile,automation,metallurgy,petrochemical,a nd other fields.We provide a wide range of protective products that can help people to prepare for emergencies and natural disasters.Our customers in Europe,United Kingdom,South America,Middle East and Africa are highly depended on our products.
                          </span></font>
                          </div>
                          <div align="center" style="font-size: 13px;"><span style="color: rgb(16, 87, 153);"><span
                              style="font-family: 微软雅黑; font-size: 14px;"><b>——</b></span></span><span
                              style="color: rgb(89, 87, 87); font-family: 微软雅黑; font-size: 14px;"><br></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> <!----> <!----> <!----></div>
          </div>
        </div> <!---->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyInfo"
}
</script>

<style scoped>
.CompanyInfo {
  width: 100%;

}

.top {
  width: 100%;
}

.main {
  width: 1400px;
}

.top img {
  height: 500px;
}

.el-menu-item a {
  font-size: 26px;
}
.main{
  margin: 30px auto;
}
.jz_web_row__content{
  padding-top: 4.413347685683531vw;
}
.main-text-two img{
  width: 500px;
}
</style>
