<template>
  <div class="ContactUs">
    <div class="page-banner">
      <img alt="Company Image 1" src="../assets/ContactUs-bannner.jpg" style="width: 100%;">
    </div>
    <div class="main">
      <div id="rowline248" class="jz_web_row_line jz_web_row_line_pc1"><!---->
        <div id="row248" class="jz_web_row jz_web_row1 jz_module_row jz_web_row_mobi1 jz_web_row_bg"><!---->
          <div class="jz_web_row__content">
            <div _index="0" _row="248" class="jz_web_col jz_web_col0 col_mobi_order_-1 J_disable_add_material">
              <div class="web_col_content">
                <div id="module420" rowid="248" projectid="420" module-index="0" module=""
                     class="form jz_module jz_module_style_6 responsive_fontSize jz_project jz_project_420">
                  <div class="module_wrap J_project_sort_handle"><!---->
                    <div class="module_content">
                      <div class="module_text_content" id="420" rowid="248" module-index="0">
                        <div class="fkeditor-wrap">
                          <div class="fk-editor">
                            <div style="line-height: 30px;" align="center"><span
                                style="font-size: 18px; text-align: left;"><b style=""><span
                                style="color: rgb(0, 0, 0);">Contact</span></b></span></div>
                            <div align="center"><font color="#a5a5a5"><span style="font-size: 13px;"><span
                                style="color: rgb(75, 75, 75);">▼</span></span></font></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> <!----> <!----> <!----></div>
              </div>
            </div> <!----></div> <!----></div>
      </div>
      <div class="area-padding-large">
        <div class="container">
          <div class="row">
            <el-row>
              <el-col :span="6">
                <div class="area-details">
                  <ul>
                    <li class="single-quick-contact">
                      <h5>Contact Number</h5>
                      <p>
                        <a>+65 84078778 </a>
                      </p>
                    </li>
                    <li class="single-quick-contact">
                      <h5>Contact Location</h5>
                      <p>150BeachRoad#28-05/06Gateway West Singapore</p>
                    </li>
                  </ul>
                </div>
              </el-col>
              <el-col :span="18">
                <div class="area-form mr-0 mr-lg-3">
                  <div class="small-title">
                    <h3>Message Box</h3>
                    <p>We look forward to your contact and suggestions. Please leave us a message.</p>
                  </div>
                  <el-form class="demo-form-inline">
                    <el-form-item>
                      <el-input placeholder="name" style="width: 100%"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input placeholder="E-mail"></el-input>
                    </el-form-item>
                    <el-form-item >
                      <el-input placeholder="Phone"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input placeholder="Subject"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 100%">
                      <el-input
                          type="textarea"
                          :rows="5"
                          placeholder="Message">
                      </el-input>

                    </el-form-item>
                    <el-form-item style="width: 100%">
                      <el-button type="primary" style="width: 100%">Submit</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs" //
}
</script>

<style scoped>
.page-banner {
  display: block;
  position: relative;
  z-index: 1;
  background-size: cover;
}

.page-banner:after {
  background: #033b9e;
  opacity: 0.5;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  border-radius: 4px;
}
.main{

  width: 1400px;
  margin: auto;
  margin-top: 60px;
}
.area-padding-large {
  padding: 100px 0;
  padding: 0 30px 100px;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--bs-gutter-y));
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}
.small-title {
  margin-bottom: 30px;
}
.area-us-area .small-title h3 {
  font-family: 'Work Sans', sans-serif;
  position: relative;
}
.area-us-area .small-title h3::after {
  background: #777777 none repeat scroll 0 0;
  bottom: -5px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 40px;
}
.small-title p {
  margin-top: 20px;
  margin-bottom: 0;
}
.area-details ul {
  list-style: outside none none;
  padding: 0;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
}
h5, .h5 {
  font-size: 20px;
  margin-bottom: 15px;
}
.single-quick-contact a {
  color: #777777;
  display: block;
  font-size: 15px;
}
.small-title {
  margin-bottom: 30px;
}
.area-us-area .small-title h3 {
  font-family: 'Work Sans', sans-serif;
  position: relative;
}
.area-us-area .small-title h3::after {
  background: #777777 none repeat scroll 0 0;
  bottom: -5px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 40px;
}
.small-title p {
  margin-top: 20px;
  margin-bottom: 0;
}
.area-form form {
  margin-left: -6px;
  margin-right: -6px;
}
>>> .el-form-item__content{
  width: 100%;
}
.area-form form {
  margin-left: -6px;
  margin-right: -6px;
  display: flex;
  flex-wrap: wrap;
}
.el-form-item{
  width: 50%;
  padding: 0 15px;
}
</style>
