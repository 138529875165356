
<template>
  <div class="footer">
    <div class="footer-content">
      <p>© 2023 Your Company Name. All rights reserved.</p>
      <p>Address: 150 Beach Road #28-05/06 Gateway West, Singapore</p>
      <p>Contact: <a>+65 84078778</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
.footer {
  background-color: #000; /* 设置底部背景颜色为黑色 */
  color: #fff; /* 设置文字颜色为白色 */
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
}

.footer-content p {
  margin: 5px 0;
}

.footer-content a {
  color: #fff;
  text-decoration: none;
}

.footer-content a:hover {
  text-decoration: underline;
}
</style>
