<template>
  <div id="app">
    <div class="header-container">
      <div></div>
      <div class="header">
        <div class="logo">
          <img src="@/assets/logo.png" alt="Logo">
        </div>
        <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
        >
          <el-menu-item index="1">
            <router-link to="/">HOME</router-link>
          </el-menu-item>
          <el-menu-item index="2">
            <router-link to="/product-info">PRODUCTS</router-link>
          </el-menu-item>
          <el-menu-item index="3">
            <router-link to="/company-info">About Us</router-link>
          </el-menu-item>
          <el-menu-item index="4">
            <router-link to="/contact-us">CONTACTS</router-link>
          </el-menu-item>
        </el-menu>
      </div>
      <div></div>
    </div>

    <router-view/>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from './views/AppFooter.vue';
export default {
  data() {
    return {
      activeIndex: '1',
    };
  },
  components: {
    AppFooter
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style>
#app {
  min-width: 1400px;
  min-height: 100%;
  background-color: #b9bdaf; /* 设置背景颜色 */
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body{
  width: 100%;
  height: 100%;
}
</style>
<style scoped>
.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  background-color: #b9bdaf;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #b9bdaf; /* 设置背景颜色 */
  width: 1400px;
  padding: 10px 20px;
}
.logo img {
  height: 120px; /* 根据需要调整 Logo 高度 */
}
.el-menu{
  background-color: #b9bdaf;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 0;
}
.el-menu-demo {
  display: flex;
  justify-content: flex-end;
  border: none; /* 去掉默认边框 */
}

.el-menu-item {
  margin-right: 20px;
}
.el-menu.el-menu--horizontal{
  border-bottom: none;
}
.el-menu-item a {
  text-decoration: none;
  color: #333;
  font-size: 26px;
}
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
  background-color: #b9bdaf;
}
.el-menu--horizontal>.el-menu-item {
  color: #FFFFFF;
}
</style>
