<template>
  <div class="ProductDetail">
    <!-- Banner -->
    <div class="banner"></div>
    <div class="detail-container">
      <!-- 上半部分 -->
      <div class="top-section">
        <div class="image-container">
          <img
              :src="require(`@/assets/${product.image}`)"
              alt="product detail"
              class="product-image">
        </div>
        <div class="info-container">
          <div data-v-062e1a0e="" class="detail_price_wrap1">
            <div data-v-062e1a0e="" class="detail_price_content">
              <div data-v-062e1a0e="" class="detail_price_left">
                <span data-v-062e1a0e="" class="detail_value jz_theme_font_color">ITEM NO : {{ product.name }}</span>
              </div>
            </div>
          </div>
          <div data-v-062e1a0e="" class="detail_price_wrap">
            <div data-v-062e1a0e="" class="detail_price_content">
              <div data-v-062e1a0e="" class="detail_price_left">
                Description: {{product.description}}
              </div>
            </div>
          </div>
          <div data-v-062e1a0e="" class="detail_price_wrap" v-if="product.size">
            <div data-v-062e1a0e="" class="detail_price_content">
              <div data-v-062e1a0e="" class="detail_price_left">
                size:{{product.size}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 下半部分 -->
      <div class="description-section">
        <h2 class="section-title">product details</h2>
        <div class="description-content" >
          <p>Our company is a professional trading company focused on high-quality safety gloves products, dedicated to providing safe, durable, and environmentally friendly glove solutions for global customers, and has become a reliable partner in the rubber glove field at home and abroad.</p>
          <p>We specialize in various types of rubber gloves, including latex, nitrile gloves, PVC gloves, etc., which are widely used in medical, laboratory, food processing, industrial protection, cleaning and care, and other fields All products have passed international certifications such as ISO, CE, and FDA, ensuring compliance with the highest industry standards. </p>

          <p>Service Advantages: </p>
          <p>1. **Quality Assurance Strictly control product quality, and both raw materials and production processes have undergone multiple inspections to ensure that every pair of gloves is safe and reliable. </p>
          <p>2.**Custom Service**: Provide customized services such as different specifications, colors, thicknesses, and packaging according to customer needs to meet diverse demands. </p>
          <p>**Global Supply**: Cooper with several internationally renowned rubber glove manufacturers to ensure a stable supply and support large-volume order delivery. </p>
          <p> **Efficient Logistics**: Relying on a perfect logistics, we provide customers with fast and convenient distribution services to reduce transportation costs. 5. **Professional Team**: We have an experienced sales and technical support team to provide customers one-stop services from product selection to after-sales service. </p>
          <p>**Corporate Vision:**</p>
          <p>With the mission of "Protecting hands, conveying safety", we are committed to becoming leading supplier in the global rubber glove field. We always insist on customer-centered, continuous innovation, pursuit of excellence, and create greater value for customers.</p>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ProductDetail',
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formattedDescription() {
      return (this.product.description || '暂无详细描述')
          .split('\n').join('<br>')
    }
  }
}
</script>

<style scoped>
.ProductDetail{
  background-color: #b9bdaf;
  padding-bottom: 60px;
}
/* Banner样式 */
.banner {
  width: 100%;
  height: 300px;
  background: url('@/assets/banner1.jpg') no-repeat center/cover;
  margin-bottom: 30px;
}
.detail-container {
  max-width: 1400px;
  margin: 30px auto;
  padding: 0 20px;


}

.top-section {
  display: flex;
  gap: 40px;
  margin-bottom: 50px;
  padding-top: 20px;
}

.image-container {
  max-width: 700px;
}

.product-image {
  width: 100%;
  height: 700px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.info-container {
  flex: 1;
}

.product-title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
  display: -webkit-box;
  font-weight: inherit;
  min-width: calc(100% - 120px);
  overflow: hidden;
  padding-right: 20px;
  color: #333;
  display: flex;
  font-size: 20px;
  line-height: 40px;
  margin-top: 10px;
}

.attributes-list {
  list-style: none;
  padding: 0;
}

.attribute-item {
  display: flex;
  align-items: center;
  padding: 35px 0;
}

.attribute-label {
  flex: 0 0 100px;
  font-weight: 600;
  color: #666;
}

.attribute-value {
  flex: 1;
  color: #333;
  font-size: 16px;
}

.description-section {
  background: #f9f9f9;
  border-radius: 12px;
  padding: 40px;
}

.section-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 2px solid #409eff;
}

.description-content {
  line-height: 1.8;
  color: #444;
  font-size: 16px;
  text-align: justify;
}

@media (max-width: 768px) {
  .top-section {
    padding-top: 20px;
    flex-direction: column;
  }

  .product-image {
    height: 300px;
  }

  .product-title {
    font-size: 24px;
  }

  .attribute-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .attribute-label {
    margin-bottom: 8px;
  }

}
.detail_price_wrap1 {
  background-color: #f7f7f7;
  height: 0;
  padding-bottom: 14.8%;
  position: relative;
  width: 100%;
}
.detail_price_wrap {
  background-color: #f7f7f7;
  height: 0;
  margin-top: 36px;
  padding-bottom: 14.8%;
  position: relative;
  width: 100%;
}
.detail_price_content {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.detail_price_left {
  align-items: center;
  display: flex;
  margin-left: 25px;
}
.detail_price {
  font-weight: 700;
}
.detail_price .detail_coin {
  font-size: 24px;
}
.detail_price .detail_value {
  font-size: 30px;
}
</style>
